@mixin hook-switcher-misc {
	.uk-switcher {

		>li {
			padding: $global-gutter $global-margin;
			box-shadow: 0 0 5px 3px rgba($black, .1);

			@media (min-width: $breakpoint-small) {
				padding: $global-medium-margin;

				.uk-table {
					tbody tr td:first-child {
						width: 320px;
					}
				}
			}

			@media (max-width: $breakpoint-xsmall-max) {
				a:not(.uk-button) {
					@include text-truncate;
					display: block;
				}
			}
		}
	}
}