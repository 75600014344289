@mixin hook-card {
	.thumb {
		height: calc(100vw / 2.7);
	}

	@include hover {
		.icon-arrow-right {
			transform: translateX(5px);
		}
	}

	@media (min-width: $breakpoint-medium) {
		.thumb {
			height: 241px;
		}
	}
}

@mixin hook-card-footer {
	font-weight: $semi-bold;

	.icon-arrow-right {
		margin-left: 5px;
		transition: $transition;
	}
}

@mixin hook-card-media-top {
	min-height: 250px;

	@media (min-width: $breakpoint-small) {
		min-height: calc(100vw / 3.5);
	}

	@media (min-width: $breakpoint-medium) {
		min-height: calc(100vw / 4.5);
	}

	@media (min-width: $breakpoint-large) {
		min-height: calc(100vw / 5.5);
	}

	@media (min-width: $breakpoint-xlarge) {
		min-height: calc(100vw / 7);
	}
}

@mixin hook-card-title {
	font-weight: $semi-bold;
}