@mixin hook-tab-item {
	background-color: $primary;
	position: relative;

	&::before {
		position: absolute;
		top: 0;
		content: '';
		height: 5px;
		background-color: lighten($primary, 32%);
		left: 0;
		width: 100%;
		opacity: 0;
	}
}

@mixin hook-tab-item-hover {
	background-color: darken($primary, 7%);
}

@mixin hook-tab-item-active {
	background-color: darken($primary, 7%);

	&::before {
		opacity: 1;
	}
}