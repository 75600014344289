@mixin hook-table {
	border: 1px solid $light-grey;

	@media (max-width: $breakpoint-xsmall-max) {
		display: block;

		* {
			display: block;
		}
	}
}

@mixin hook-table-misc {
	.uk-table {
		tbody tr td:first-child {
			background-color: $primary;
			color: $white;
		}

		a:not(.uk-button) {
			@include text-truncate;
			display: block;
		}

		@media (min-width: $breakpoint-small) {
			tbody tr td:first-child {
				width: 180px;
			}
		}
	}
}