@mixin hook-button {
	align-items: center;
	text-transform: uppercase;
	display: inline-flex;
	letter-spacing: .5px;
	font-weight: $bold;
	justify-content: space-between;
	max-width: 282px;
	padding: 6px 16px;
	position: relative;
	width: 100%;

	.icon-arrow-right {
		margin-left: $global-small-margin;
		transition: $transition;
	}
}

@mixin hook-button-primary {
	&.select {
		width: 100%;
		max-width: 280px;
		text-transform: none;

		>span {
			@include text-truncate;
		}

		.icon {
			height: 16px;
			transition: $speed-fast;
		}

		&[aria-expanded='true'] .icon {
			transform: rotate(180deg);
		}
	}

	@include hover {
		.icon-arrow-right {
			transform: translateX(5px);
		}
	}

	@media (min-width: $breakpoint-small) {
		&.select {
			max-width: 350px;
		}
	}
}

@mixin hook-button-misc {
	.uk-button {
		&-social {
			border-radius: 50%;
			width: 48px;
			height: 48px;
			justify-content: center;
			align-items: center;
			border: 2px solid $primary;
			padding: 0;
			color: $primary;

			@include hover {
				background-color: $primary;
				color: $white;
			}
		}
	}
}
