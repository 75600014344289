@mixin hook-navbar {
	padding-left: $global-margin;
	padding-right: $global-margin;

	@media (min-width: $breakpoint-medium) {
		padding-left: $global-medium-margin;
		padding-right: $global-medium-margin;
	}
}

@mixin hook-navbar-nav-item {
	position: relative;
	overflow: hidden;
	text-transform: uppercase;

	&::before {
		position: absolute;
		content: '';
		left: 0;
		bottom: 0;
		transform: translateX(-101%);
		background-color: $white;
		height: 1px;
		width: 100%;
		transition: $speed-fast $timing;
	}
}

@mixin hook-navbar-nav-item-hover {
	&::before {
		transform: translateX(0);
	}
}

@mixin hook-navbar-nav-item-active {
	&::before {
		transform: translateX(0);
	}
}

@mixin hook-navbar-misc {
	.uk-navbar {
		&-container {
			max-width: 1800px;
		}

		&-nav {
			@media (min-width: $breakpoint-medium) {
				gap: $global-medium-margin; // sass-lint:disable-line no-misspelled-properties
			}

			@media (max-width: $breakpoint-xsmall-max) {
				>li:first-child {
					display: none;
				}
			}
		}
	}
}