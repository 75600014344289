//
// Main
//

// Background Icon
@mixin bg-icon($icon) {
	background-image: url('data:image/svg+xml;utf8,' + $icon);
	background-repeat: no-repeat;
}

// Hover
@mixin hover {
	&:hover,
	&:focus,
	&:active,
	&.uk-open,
	&.uk-active {
		@content;
	}
}

// Image caption
@mixin image-caption {
	font-size: 0.8rem;
	padding: $global-small-margin;
	text-align: center;
}

// Pseudo Icon
@mixin pseudo-icon($icon) {
	@include bg-icon($icon);
	content: '';
	background-size: contain;
	display: inline-block;
}

// Truncate text
@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


//
// UIkit
// https://github.com/uikit/uikit/tree/master/src/scss
//
// Imports UIkit mixin overrides for the theme
// These are not used by main.scss, although loaded
//
// ========================================================================

// Base
@import 'uikit/base';

// Elements
@import 'uikit/table';
@import 'uikit/form';
@import 'uikit/button';

// Layout
@import 'uikit/section';
@import 'uikit/card';

// Navs
@import 'uikit/navbar';
@import 'uikit/breadcrumb';
@import 'uikit/tab';

// JavaScript
@import 'uikit/switcher';

// Utilities
@import 'uikit/text';
